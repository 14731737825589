
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { FormControl, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import type { Category } from "@/types";

interface CategorySelectProps {
  value: number | undefined;
  onChange: (value: number | undefined) => void;
}

const CategorySelect = ({ value, onChange }: CategorySelectProps) => {
  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      console.log("Fetching categories");
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .order("name");
      if (error) {
        console.error("Error fetching categories:", error);
        throw error;
      }
      console.log("Fetched categories:", data);
      return data as Category[];
    },
  });

  return (
    <FormItem>
      <FormLabel>Category</FormLabel>
      <FormControl>
        <select
          className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          value={value || ""}
          onChange={(e) => {
            const newValue = e.target.value ? Number(e.target.value) : undefined;
            console.log("Selected category:", newValue);
            onChange(newValue);
          }}
        >
          <option value="">Select a category</option>
          {categories?.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};

export default CategorySelect;
