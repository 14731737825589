
import { cn } from "@/lib/utils";

interface ImagePreviewProps {
  src: string;
  className?: string;
}

const ImagePreview = ({ src, className }: ImagePreviewProps) => {
  return (
    <div className={cn("mt-4", className)}>
      <div className="relative w-full max-w-md bg-gray-50 rounded-lg overflow-hidden">
        <img 
          src={src} 
          alt="Featured" 
          className="w-full h-auto object-contain rounded-lg shadow-sm" 
          onError={(e) => {
            console.error('Image failed to load:', src);
            e.currentTarget.src = '/placeholder.svg';
          }}
        />
      </div>
      <p className="text-sm text-gray-500 mt-2 break-all">
        Current image URL: {src}
      </p>
    </div>
  );
};

export default ImagePreview;
