
import { stripHtmlTags } from '../utils';

interface ListItem {
  text: string;
  checked?: boolean;
}

interface ListBlockProps {
  items?: string[] | ListItem[];
  style?: 'ordered' | 'unordered';
}

const ListBlock = ({ items = [], style = 'unordered' }: ListBlockProps) => {
  const ListTag = style === 'ordered' ? 'ol' : 'ul';
  
  return (
    <ListTag>
      {Array.isArray(items) && items.map((item, i) => (
        <li key={i}>
          {typeof item === 'string' 
            ? stripHtmlTags(item)
            : stripHtmlTags(item.text)
          }
        </li>
      ))}
    </ListTag>
  );
};

export default ListBlock;
