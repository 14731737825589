
import { cn } from "@/lib/utils";

export const getStatusStyles = (status: string) => {
  const baseStyles = "px-2 py-1 rounded-md text-xs font-medium uppercase inline-block";
  
  switch (status) {
    case "draft":
      return cn(baseStyles, "bg-yellow-100 text-yellow-800");
    case "published":
      return cn(baseStyles, "bg-green-100 text-green-800");
    case "deleted":
      return cn(baseStyles, "bg-red-100 text-red-800");
    default:
      return cn(baseStyles, "bg-gray-100 text-gray-800");
  }
};
