
import { Link } from "react-router-dom";
import { BlogPost } from "@/types";
import { stripHtmlTags } from "./utils";
import { convertHtmlToEditorJs } from "@/utils/htmlToEditorJs";

interface FeaturedPostProps {
  post: BlogPost;
}

const FeaturedPost = ({ post }: FeaturedPostProps) => {
  const getExcerpt = (content: string | undefined, format: 'html' | 'editorjs' = 'html') => {
    if (!content) return "";

    let cleanContent = "";
    
    if (format === 'editorjs') {
      try {
        const parsedContent = JSON.parse(content);
        // Get text from the first paragraph block
        const firstParagraph = parsedContent.blocks.find(
          (block: any) => block.type === 'paragraph'
        );
        cleanContent = firstParagraph?.data?.text || "";
      } catch (e) {
        console.error('Error parsing EditorJS content:', e);
        cleanContent = "";
      }
    } else {
      cleanContent = stripHtmlTags(content);
    }

    // Get first 150 characters and trim to last complete word
    return cleanContent.substring(0, 150).split(" ").slice(0, -1).join(" ") + "...";
  };

  return (
    <div className="mb-16">
      <Link to={`/articles/${post.slug}`} className="block group">
        <article className="relative overflow-hidden rounded-2xl bg-white">
          <div className="grid md:grid-cols-2 gap-8 p-8">
            {post.featured_image && (
              <div className="aspect-[16/9] rounded-lg overflow-hidden">
                <img
                  src={post.featured_image}
                  alt={post.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
              </div>
            )}
            <div className="flex flex-col justify-center">
              <span className="inline-block px-4 py-1.5 mb-4 text-sm font-medium bg-warm rounded-full">
                Featured Article
              </span>
              <h1 className="text-2xl md:text-3xl font-serif text-left mb-4 line-clamp-3">
                {post.title}
              </h1>
              <p className="text-primary-muted line-clamp-3">
                {getExcerpt(post.content, post.content_format)}
              </p>
            </div>
          </div>
        </article>
      </Link>
    </div>
  );
};

export default FeaturedPost;
