
import React from "react";
import { Mail, Headphones, Youtube, Twitter, Instagram } from "lucide-react";
import { ButtonColorful } from "@/components/ui/button-colorful";

const linkData = [
  {
    title: "Join My Newsletter",
    description: "Get exclusive insights & life hacks straight to your inbox.",
    icon: <Mail className="w-5 h-5" />,
    url: "https://substack.com/@codymclain",
    gradientFrom: "from-indigo-500",
    gradientVia: "via-purple-600", 
    gradientTo: "to-violet-500"
  },
  {
    title: "Listen to My Podcast",
    description: "Unlock your mind—new episodes every week!",
    icon: <Headphones className="w-5 h-5" />,
    url: "https://www.mindhack.com",
    gradientFrom: "from-fuchsia-600",
    gradientVia: "via-purple-600", 
    gradientTo: "to-pink-500"
  },
  {
    title: "Watch My Videos",
    description: "Deep dives, productivity hacks, & AI—watch here!",
    icon: <Youtube className="w-5 h-5" />,
    url: "https://www.youtube.com/@cody-mclain",
    gradientFrom: "from-orange-500",
    gradientVia: "via-amber-500", 
    gradientTo: "to-yellow-500"
  },
  {
    title: "Follow Me on Twitter/X",
    description: "No fluff, just insights & hot takes!",
    icon: <Twitter className="w-5 h-5" />,
    url: "https://twitter.com/codymclain",
    gradientFrom: "from-blue-500",
    gradientVia: "via-cyan-500", 
    gradientTo: "to-sky-400"
  },
  {
    title: "Follow Me on Instagram",
    description: "Behind the scenes & personal updates!",
    icon: <Instagram className="w-5 h-5" />,
    url: "https://instagram.com/codysview",
    gradientFrom: "from-purple-500",
    gradientVia: "via-fuchsia-500", 
    gradientTo: "to-pink-500"
  }
];

const LinkButtons = () => {
  return (
    <div className="space-y-4 mb-12">
      {linkData.map((link, index) => (
        <ButtonColorful
          key={index}
          label={link.title}
          description={link.description}
          icon={link.icon}
          gradientFrom={link.gradientFrom}
          gradientVia={link.gradientVia}
          gradientTo={link.gradientTo}
          onClick={() => window.open(link.url, '_blank')}
        />
      ))}
    </div>
  );
};

export default LinkButtons;
