import { type ReactNode } from "react";
import { Form } from "@/components/ui/form";
import { UseFormReturn } from "react-hook-form";
import { BlogPost } from "@/types";

interface FormLayoutProps {
  children: ReactNode;
  onSubmit: (e: React.FormEvent) => void;
  form: UseFormReturn<Partial<BlogPost>>;
}

const FormLayout = ({ children, onSubmit, form }: FormLayoutProps) => {
  return (
    <Form {...form}>
      <form onSubmit={onSubmit} className="space-y-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {children}
        </div>
      </form>
    </Form>
  );
};

export default FormLayout;