
import { type Control } from "react-hook-form";
import { type BlogPost } from "@/types";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import ContentEditor from "./ContentEditor";
import StatusSelect from "./StatusSelect";
import CategorySelect from "./CategorySelect";

interface MainContentProps {
  control: Control<Partial<BlogPost>>;
}

const MainContent = ({ control }: MainContentProps) => {
  return (
    <div className="lg:col-span-2 space-y-8">
      {/* Title Section */}
      <div className="border-b pb-6 mb-8">
        <FormField
          control={control}
          name="title"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input 
                  {...field} 
                  className="text-[5rem] leading-tight font-serif border-none px-0 focus-visible:ring-0 placeholder:text-gray-400" 
                  placeholder="Article Title"
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      {/* Meta Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4 bg-accent/20 rounded-lg">
        <FormField
          control={control}
          name="status"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Status</FormLabel>
              <StatusSelect
                value={field.value || 'draft'}
                onChange={field.onChange}
              />
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="category_id"
          render={({ field }) => (
            <CategorySelect
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <FormField
          control={control}
          name="meta_description"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Meta Description</FormLabel>
              <FormControl>
                <Input {...field} placeholder="SEO meta description" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={control}
          name="slug"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slug</FormLabel>
              <FormControl>
                <Input {...field} placeholder="article-slug" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      {/* Main Content */}
      <FormField
        control={control}
        name="content"
        render={({ field }) => (
          <ContentEditor
            label="Content"
            value={field.value || ''}
            onChange={field.onChange}
            height={500}
          />
        )}
      />
    </div>
  );
};

export default MainContent;
