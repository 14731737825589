
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ArrowUpRight } from "lucide-react";

interface ButtonColorfulProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    label?: string;
    icon?: React.ReactNode;
    description?: string;
    gradientFrom?: string;
    gradientVia?: string;
    gradientTo?: string;
}

export function ButtonColorful({
    className,
    label = "Explore Components",
    icon,
    description,
    gradientFrom = "from-indigo-500",
    gradientVia = "via-purple-500",
    gradientTo = "to-pink-500",
    ...props
}: ButtonColorfulProps) {
    return (
        <Button
            className={cn(
                "relative h-auto w-full py-6 px-4 overflow-hidden",
                "bg-zinc-900 dark:bg-zinc-100",
                "transition-all duration-200",
                "group shadow-md hover:shadow-lg",
                className
            )}
            {...props}
        >
            {/* Gradient background effect */}
            <div
                className={cn(
                    "absolute inset-0",
                    `bg-gradient-to-r ${gradientFrom} ${gradientVia} ${gradientTo}`,
                    "opacity-40 group-hover:opacity-80",
                    "transition-opacity duration-500"
                )}
            />

            {/* Content */}
            <div className="relative flex items-start w-full">
                {icon && (
                    <div className="mr-3 mt-1">
                        {icon}
                    </div>
                )}
                <div className="text-left">
                    <div className="font-medium text-white dark:text-zinc-900">{label}</div>
                    {description && (
                        <div className="text-xs opacity-90 mt-1 text-white dark:text-zinc-900">{description}</div>
                    )}
                </div>
            </div>
        </Button>
    );
}
