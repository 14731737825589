
import { useEffect } from "react";
import Navigation from "@/components/Navigation";
import { AdminDashboard } from "@/components/admin/AdminDashboard";

const Admin = () => {
  useEffect(() => {
    document.title = "Admin Dashboard | Cody McLain";
  }, []);

  return (
    <div className="min-h-screen bg-warm">
      <Navigation />
      <div className="container mx-auto px-6 py-32">
        <AdminDashboard />
      </div>
    </div>
  );
};

export default Admin;
