
const ContactHeader = () => {
  return (
    <div className="text-center mb-12">
      <h1 className="text-4xl font-avenir mb-4">Get in Touch</h1>
      <p className="text-primary-muted max-w-2xl mx-auto">
        Have a question or want to collaborate? Feel free to reach out. I'll get
        back to you as soon as possible.
      </p>
    </div>
  );
};

export default ContactHeader;
