
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import Navigation from "@/components/Navigation";
import FeaturedPost from "@/components/articles/FeaturedPost";
import ArticleGrid from "@/components/articles/ArticleGrid";
import LoadingSkeleton from "@/components/articles/LoadingSkeleton";
import ErrorMessage from "@/components/articles/ErrorMessage";
import { BlogPost } from "@/types";
import Footer from "@/components/Footer";

const Articles = () => {
  useEffect(() => {
    document.title = "Articles | Cody McLain";
  }, []);

  const { data: featuredPost, isLoading: isFeaturedLoading, error: featuredError } = useQuery({
    queryKey: ["featuredPost"],
    queryFn: async () => {
      console.log("Fetching featured post");
      try {
        const { data, error } = await supabase
          .from("blog_posts")
          .select("*")
          .eq("status", "published")
          .eq("featured", true)
          .order("published_on", { ascending: false })
          .maybeSingle();

        if (error) {
          console.error("Error fetching featured post:", error);
          throw error;
        }
        
        console.log("Featured post data:", data);
        return data as BlogPost;
      } catch (error) {
        console.error("Failed to fetch featured post:", error);
        throw error;
      }
    },
    retry: 3,
  });

  const { data: posts, isLoading: isPostsLoading, error: postsError } = useQuery({
    queryKey: ["posts", featuredPost?.id],
    queryFn: async () => {
      console.log("Fetching other posts, excluding featured post ID:", featuredPost?.id);
      try {
        let query = supabase
          .from("blog_posts")
          .select("*")
          .eq("status", "published")
          .order("published_on", { ascending: false });

        if (featuredPost?.id) {
          query = query.neq("id", featuredPost.id);
        }

        const { data, error } = await query;

        if (error) {
          console.error("Error fetching posts:", error);
          throw error;
        }
        
        console.log("Other posts data:", data);
        return data as BlogPost[];
      } catch (error) {
        console.error("Failed to fetch other posts:", error);
        throw error;
      }
    },
    enabled: !isFeaturedLoading,
    retry: 3,
  });

  const isLoading = isFeaturedLoading || isPostsLoading;
  const hasError = featuredError || postsError;

  return (
    <div className="min-h-screen bg-[#233144]">
      <Navigation />
      <main className="container mx-auto px-6 pt-32 pb-16">
        {isLoading ? (
          <LoadingSkeleton />
        ) : hasError ? (
          <ErrorMessage />
        ) : !featuredPost && (!posts || posts.length === 0) ? (
          <div className="text-white text-center">
            <h2 className="text-3xl font-serif mb-4">No Articles Yet</h2>
            <p className="text-primary-muted">
              Check back soon for new articles!
            </p>
          </div>
        ) : (
          <>
            {featuredPost && <FeaturedPost post={featuredPost} />}
            {posts && posts.length > 0 && <ArticleGrid posts={posts} />}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
};

export default Articles;
