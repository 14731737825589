import { Link } from "react-router-dom";

interface ArticleCardProps {
  title: string;
  slug: string;
  featured_image?: string;
}

const ArticleCard = ({ title, slug, featured_image }: ArticleCardProps) => {
  return (
    <Link
      to={`/articles/${slug}`}
      className="group block bg-white rounded-2xl overflow-hidden hover-lift"
    >
      <article>
        <div className="aspect-[16/9] relative">
          <img
            src={featured_image || "/placeholder.svg"}
            alt={title}
            className="w-full h-full object-cover"
            onError={(e) => {
              console.error('Image failed to load:', featured_image);
              e.currentTarget.src = '/placeholder.svg';
            }}
          />
        </div>
        <div className="p-6">
          <h3 className="text-xl font-semibold group-hover:text-primary transition-colors duration-200">
            {title}
          </h3>
        </div>
      </article>
    </Link>
  );
};

export default ArticleCard;