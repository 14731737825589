
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { UseFormSetValue } from "react-hook-form";
import type { BlogPost } from "@/types";

export const useImageUpload = (
  value: string | undefined,
  setValue: UseFormSetValue<Partial<BlogPost>>
) => {
  const [uploading, setUploading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { toast } = useToast();

  const deleteExistingImage = async (imageUrl: string) => {
    try {
      const urlParts = imageUrl.split('blog-images/');
      if (urlParts.length !== 2) {
        console.error('Invalid image URL format');
        return;
      }
      const filePath = urlParts[1];

      const { error: deleteError } = await supabase.storage
        .from('blog-images')
        .remove([filePath]);

      if (deleteError) {
        console.error('Error deleting old image:', deleteError);
      }
    } catch (error) {
      console.error('Error in deleteExistingImage:', error);
    }
  };

  const verifyImage = (url: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image();
      const timeoutId = setTimeout(() => {
        img.src = "";
        resolve(false);
      }, 5000);

      img.onload = () => {
        clearTimeout(timeoutId);
        resolve(true);
      };
      img.onerror = () => {
        clearTimeout(timeoutId);
        resolve(false);
      };
      img.src = url;
    });
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      if (value) {
        await deleteExistingImage(value);
      }
      setValue('featured_image', '', {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });
      toast({
        title: "Success",
        description: "Image deleted successfully",
        variant: "default",
        className: "bg-green-500 text-white",
      });
    } catch (error) {
      console.error('Delete error:', error);
      toast({
        title: "Error",
        description: "Failed to delete image",
        variant: "destructive",
      });
    } finally {
      setDeleting(false);
    }
  };

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];
      
      if (!file) {
        return;
      }

      setUploading(true);
      
      if (file.size > 5 * 1024 * 1024) {
        throw new Error('File size must be less than 5MB');
      }

      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
      if (!allowedTypes.includes(file.type)) {
        throw new Error('File must be a JPG, PNG, GIF, or WebP image');
      }

      if (value) {
        await deleteExistingImage(value);
      }

      // Convert the file to a Buffer/Uint8Array to ensure proper upload
      const arrayBuffer = await file.arrayBuffer();
      const fileBuffer = new Uint8Array(arrayBuffer);

      const fileExt = file.name.split('.').pop()?.toLowerCase();
      const fileName = `${crypto.randomUUID()}.${fileExt}`;

      const { error: uploadError } = await supabase.storage
        .from('blog-images')
        .upload(fileName, fileBuffer, {
          cacheControl: '3600',
          contentType: file.type,
          upsert: false
        });

      if (uploadError) {
        throw uploadError;
      }

      const { data: { publicUrl } } = supabase.storage
        .from('blog-images')
        .getPublicUrl(fileName);

      setVerifying(true);
      const isAccessible = await verifyImage(publicUrl);
      
      if (!isAccessible) {
        throw new Error('Unable to verify uploaded image. Please try again.');
      }

      setValue('featured_image', publicUrl, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true
      });

      toast({
        title: "Success",
        description: "Image uploaded successfully",
        variant: "default",
        className: "bg-green-500 text-white",
      });

    } catch (error) {
      console.error('Upload process error:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to upload image. Please try again.",
        variant: "destructive",
      });
    } finally {
      setUploading(false);
      setVerifying(false);
      event.target.value = '';
    }
  };

  return {
    uploading,
    verifying,
    deleting,
    handleDelete,
    handleUpload
  };
};
