
interface EmbedBlockProps {
  embed?: string;
}

const EmbedBlock = ({ embed = '' }: EmbedBlockProps) => {
  return (
    <div className="relative aspect-video w-full my-8">
      <iframe
        className="absolute inset-0 w-full h-full"
        src={embed}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default EmbedBlock;
