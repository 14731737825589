
import { useQuery } from "@tanstack/react-query";
import { BlogPost } from "@/types";
import { Table, TableBody } from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { useState, useEffect } from "react";
import { 
  fetchArticles, 
  handleArticleFeature,
  handleBulkStatusChange,
  handleBulkDelete,
  handleBulkRecover
} from "./articles/utils/articleQueries";
import { useArticleSort } from "./articles/hooks/useArticleSort";
import BulkActions from "./articles/components/BulkActions";
import ArticlesTableHeader from "./articles/components/ArticlesTableHeader";
import ArticleTableRow from "./articles/components/ArticleTableRow";

interface ArticlesTableProps {
  showDeleted: boolean;
}

const ArticlesTable = ({ showDeleted }: ArticlesTableProps) => {
  const { toast } = useToast();
  const [selectedArticles, setSelectedArticles] = useState<string[]>([]);
  
  const { data: articles, refetch } = useQuery({
    queryKey: ["adminArticles", showDeleted],
    queryFn: () => fetchArticles(showDeleted),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const { sortField, sortDirection, handleSort, sortedArticles } = useArticleSort(articles);

  useEffect(() => {
    const ensureSingleFeatured = async () => {
      if (!articles || articles.length === 0) return;

      const featuredArticles = articles.filter(article => article.featured);
      
      if (featuredArticles.length === 0) {
        try {
          await handleArticleFeature(articles[0].id);
          refetch();
        } catch (error) {
          console.error("Error featuring most recent article:", error);
        }
      } else if (featuredArticles.length > 1) {
        const mostRecentFeatured = featuredArticles.sort(
          (a, b) => new Date(b.created_on!).getTime() - new Date(a.created_on!).getTime()
        )[0];
        
        try {
          await handleArticleFeature(mostRecentFeatured.id);
          refetch();
        } catch (error) {
          console.error("Error updating featured articles:", error);
        }
      }
    };

    ensureSingleFeatured();
  }, [articles]);

  const onFeatureToggle = async (id: string) => {
    try {
      await handleArticleFeature(id);
      toast({
        title: "Success",
        description: "Featured article updated successfully",
      });
      refetch();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update featured article",
        variant: "destructive",
      });
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedArticles(sortedArticles.map(article => article.id));
    } else {
      setSelectedArticles([]);
    }
  };

  const handleSelectArticle = (id: string, checked: boolean) => {
    if (checked) {
      setSelectedArticles(prev => [...prev, id]);
    } else {
      setSelectedArticles(prev => prev.filter(articleId => articleId !== id));
    }
  };

  const handleBulkStatusUpdate = async (newStatus: 'draft' | 'published') => {
    try {
      await handleBulkStatusChange(selectedArticles, newStatus);
      toast({
        title: "Success",
        description: `${selectedArticles.length} articles updated to ${newStatus}`,
      });
      setSelectedArticles([]);
      refetch();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update article statuses",
        variant: "destructive",
      });
    }
  };

  const handleBulkArticleDelete = async () => {
    try {
      await handleBulkDelete(selectedArticles);
      toast({
        title: "Success",
        description: `${selectedArticles.length} articles moved to trash`,
      });
      setSelectedArticles([]);
      refetch();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to delete articles",
        variant: "destructive",
      });
    }
  };

  const handleBulkArticleRecover = async () => {
    try {
      await handleBulkRecover(selectedArticles);
      toast({
        title: "Success",
        description: `${selectedArticles.length} articles recovered`,
      });
      setSelectedArticles([]);
      refetch();
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to recover articles",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="h-10 mb-4">
        <BulkActions
          selectedCount={selectedArticles.length}
          showDeleted={showDeleted}
          onBulkStatusUpdate={handleBulkStatusUpdate}
          onBulkDelete={handleBulkArticleDelete}
          onBulkRecover={handleBulkArticleRecover}
        />
      </div>
      <div className="rounded-md border">
        <Table>
          <ArticlesTableHeader
            onSelectAll={handleSelectAll}
            onSort={handleSort}
            allSelected={selectedArticles.length === sortedArticles.length}
            hasArticles={sortedArticles.length > 0}
          />
          <TableBody>
            {sortedArticles.map((article) => (
              <ArticleTableRow
                key={article.id}
                article={article}
                selected={selectedArticles.includes(article.id)}
                onSelect={handleSelectArticle}
                onFeatureToggle={onFeatureToggle}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ArticlesTable;
