
import { FormControl, FormItem, FormMessage } from "@/components/ui/form";
import { cn } from "@/lib/utils";

interface StatusSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const StatusSelect = ({ value, onChange }: StatusSelectProps) => {
  const getStatusStyles = (status: string) => {
    const baseStyles = "flex h-10 w-full rounded-md px-3 py-2 text-sm focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50";
    
    return status === "published"
      ? cn(baseStyles, "bg-green-100 border-green-200")
      : cn(baseStyles, "bg-yellow-100 border-yellow-200");
  };

  return (
    <FormItem>
      <FormControl>
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className={getStatusStyles(value)}
        >
          <option value="draft">Draft</option>
          <option value="published">Published</option>
        </select>
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};

export default StatusSelect;
