
import { stripHtmlTags } from '../utils';

interface QuoteBlockProps {
  text?: string;
  caption?: string;
}

const QuoteBlock = ({ text = '', caption }: QuoteBlockProps) => {
  return (
    <blockquote className="border-l-4 border-gray-300 pl-4 my-4">
      <p>{stripHtmlTags(text)}</p>
      {caption && (
        <footer className="text-gray-600">— {stripHtmlTags(caption)}</footer>
      )}
    </blockquote>
  );
};

export default QuoteBlock;
