
import { useForm } from "react-hook-form";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { BlogPost } from "@/types";
import FormLayout from "./article-form/FormLayout";
import MainContent from "./article-form/MainContent";
import Sidebar from "./article-form/Sidebar";

interface ArticleFormProps {
  initialData?: BlogPost | null;
  onSubmit: (data: Partial<BlogPost>) => void;
}

const ArticleForm = ({ initialData, onSubmit }: ArticleFormProps) => {
  const { toast } = useToast();
  console.log('ArticleForm initialized with data:', initialData);
  
  const form = useForm<Partial<BlogPost>>({
    defaultValues: {
      title: initialData?.title || "",
      slug: initialData?.slug || "",
      content: initialData?.content || "",
      meta_description: initialData?.meta_description || "",
      featured_image: initialData?.featured_image || "",
      image_attribution: initialData?.image_attribution || "",
      status: initialData?.status || "draft",
      category_id: initialData?.category_id,
    },
  });

  console.log('Form default values set:', form.getValues());

  const verifyArticleSave = async (articleId: string, expectedData: Partial<BlogPost>) => {
    console.log("Verifying article save for ID:", articleId);
    
    const { data: savedArticle, error } = await supabase
      .from("blog_posts")
      .select("*")
      .eq("id", articleId)
      .single();

    if (error) {
      console.error("Error verifying article save:", error);
      return false;
    }

    console.log("Verification - Saved article data:", savedArticle);
    console.log("Verification - Expected data:", expectedData);

    // Check if critical fields match
    const fieldsMatch = 
      savedArticle.title === expectedData.title &&
      savedArticle.content === expectedData.content &&
      savedArticle.slug === expectedData.slug;

    console.log("Verification result - Fields match:", fieldsMatch);
    return fieldsMatch;
  };

  const handleSubmit = async (data: Partial<BlogPost>) => {
    try {
      console.log("Form submission started with data:", data);
      
      if (!data.title || !data.slug) {
        console.error("Missing required fields:", { title: data.title, slug: data.slug });
        toast({
          title: "Error",
          description: "Title and slug are required",
          variant: "destructive",
        });
        return;
      }

      console.log("Calling onSubmit with data:", {
        ...data,
        id: initialData?.id,
        content: data.content || "",
        status: data.status || "draft",
        category_id: data.category_id,
      });

      await onSubmit({
        ...data,
        id: initialData?.id,
        content: data.content || "",
        status: data.status || "draft",
        category_id: data.category_id,
      });
      
      // Verify the save if we have an article ID
      if (initialData?.id) {
        const isVerified = await verifyArticleSave(initialData.id, data);
        
        if (isVerified) {
          console.log("Article save verified successfully");
          toast({
            title: "Success",
            description: "Article updated and verified successfully",
            variant: "default",
            className: "bg-green-500 text-white",
          });
        } else {
          console.error("Article save verification failed");
          toast({
            title: "Warning",
            description: "Article was saved but verification failed. Please check your changes.",
            variant: "destructive",
          });
        }
      } else {
        // For new articles
        toast({
          title: "Success",
          description: "New article created successfully",
          variant: "default",
          className: "bg-green-500 text-white",
        });
      }
      
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Error",
        description: "Failed to update article",
        variant: "destructive",
      });
    }
  };

  return (
    <FormLayout 
      onSubmit={form.handleSubmit(handleSubmit)}
      form={form}
    >
      <MainContent control={form.control} />
      <Sidebar 
        control={form.control}
        setValue={form.setValue}
        isEditing={!!initialData}
      />
    </FormLayout>
  );
};

export default ArticleForm;
