
const HeroSection = () => {
  return (
    <section className="relative h-[60vh] flex items-center justify-center overflow-hidden">
      <div 
        className="absolute inset-0 bg-cover bg-center"
        style={{ 
          backgroundImage: 'url("https://images.unsplash.com/photo-1506744038136-46273834b3fb")',
          filter: 'brightness(0.7)'
        }}
      />
      <div className="absolute inset-0 bg-black/40" />
      <div className="relative z-10 container mx-auto px-6">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-5xl md:text-6xl font-avenir mb-6 leading-tight tracking-tight text-white animate-fade-up">
            Turning Obstacles into Opportunities
          </h1>
          <p className="text-xl text-white/90 leading-relaxed italic font-serif animate-fade-up">
            "Every day, I meditate for my soul, exercise for my body, and read for my mind. 
            These simple practices fuel my drive to grow, inspire, and make an impact."
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
