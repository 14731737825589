
const BookHero = () => {
  return (
    <section className="relative h-[60vh] flex items-center justify-center overflow-hidden bg-primary">
      <div 
        className="absolute inset-0 bg-cover bg-[center_25%] brightness-[0.85] opacity-70"
        style={{ 
          backgroundImage: 'url("/lovable-uploads/b8164c9e-d517-4a0b-ba05-861e508eaca2.png?w=1200")',
        }}
      />
      <div className="absolute inset-0 bg-black/40" />
      <div className="relative z-10 container mx-auto px-6">
        <div className="grid md:grid-cols-2 items-center gap-8 max-w-5xl mx-auto">
          <div>
            <h1 className="text-5xl md:text-6xl font-avenir mb-6 leading-tight tracking-tight text-white animate-fade-up">
              About the Book
            </h1>
            <p className="text-xl text-white/90 leading-relaxed italic font-serif animate-fade-up">
              From foster care to successful entrepreneur, discover the mindsets and strategies that can transform any obstacle into an opportunity.
            </p>
          </div>
          {/* Removing the broken image */}
          <div className="hidden md:block"></div>
        </div>
      </div>
    </section>
  );
};

export default BookHero;
