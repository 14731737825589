
import React from "react";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import LinksHero from "@/components/links/LinksHero";
import LinkButtons from "@/components/links/LinkButtons";
import SocialIcons from "@/components/links/SocialIcons";

const Links = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white">
      <Navigation />
      <main className="flex-grow pt-24 pb-16">
        <div className="container mx-auto px-4 max-w-md">
          <LinksHero />
          <LinkButtons />
          <SocialIcons />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Links;
