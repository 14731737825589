
import { Button } from "@/components/ui/button";
import { Facebook, Twitter, Linkedin } from "lucide-react";

const CallToAction = () => {
  const shareUrl = window.location.href;
  const bookTitle = "From Foster Care to Millionaire by Cody McLain";

  const shareLinks = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(bookTitle)}&url=${encodeURIComponent(shareUrl)}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(bookTitle)}`
  };

  return (
    <section className="py-16 bg-[#233144] text-white">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-serif mb-8">Get Your Copy Today</h2>
          
          <div className="space-y-6">
            <div className="flex flex-wrap justify-center gap-4">
              <Button
                className="bg-[#FF9900] hover:bg-[#FF9900]/90 text-white min-w-[200px]"
                onClick={() => window.open('https://www.amazon.com', '_blank')}
              >
                Buy on Amazon
              </Button>
              <Button
                variant="outline"
                className="bg-white text-[#233144] hover:bg-gray-100 min-w-[200px]"
                onClick={() => window.open('https://www.barnesandnoble.com/w/from-foster-care-to-millionaire-cody-mclain/1130146331', '_blank')}
              >
                Barnes & Noble
              </Button>
            </div>

            <div className="pt-8 border-t border-gray-700">
              <p className="mb-4">Share this book:</p>
              <div className="flex justify-center space-x-4">
                <a
                  href={shareLinks.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-400 transition-colors"
                >
                  <Facebook className="w-6 h-6" />
                </a>
                <a
                  href={shareLinks.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-400 transition-colors"
                >
                  <Twitter className="w-6 h-6" />
                </a>
                <a
                  href={shareLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-blue-400 transition-colors"
                >
                  <Linkedin className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
