
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface BulkActionsProps {
  selectedCount: number;
  showDeleted: boolean;
  onBulkDelete: () => void;
  onBulkRecover: () => void;
  onBulkStatusUpdate: (status: 'draft' | 'published') => void;
}

const BulkActions = ({
  selectedCount,
  showDeleted,
  onBulkDelete,
  onBulkRecover,
  onBulkStatusUpdate
}: BulkActionsProps) => {
  if (selectedCount === 0) return null;

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm text-muted-foreground">
        {selectedCount} articles selected
      </span>
      {showDeleted ? (
        <Button
          variant="default"
          size="sm"
          onClick={onBulkRecover}
        >
          Recover Selected
        </Button>
      ) : (
        <>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm">
                Change Status
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white dark:bg-gray-800 border shadow-lg z-50">
              <DropdownMenuItem className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer" onClick={() => onBulkStatusUpdate('draft')}>
                Set to Draft
              </DropdownMenuItem>
              <DropdownMenuItem className="hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer" onClick={() => onBulkStatusUpdate('published')}>
                Publish
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            variant="destructive"
            size="sm"
            onClick={onBulkDelete}
          >
            Delete Selected
          </Button>
        </>
      )}
    </div>
  );
};

export default BulkActions;
