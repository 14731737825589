import { FormControl, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import EditorWrapper from "./EditorWrapper";

interface ContentEditorProps {
  value: string;
  onChange: (content: string) => void;
  label: string;
  height?: number;
}

const ContentEditor = ({ value, onChange, label, height }: ContentEditorProps) => {
  return (
    <FormItem>
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <EditorWrapper 
          value={value || ''} 
          onChange={onChange}
          height={height}
        />
      </FormControl>
      <FormMessage />
    </FormItem>
  );
};

export default ContentEditor;