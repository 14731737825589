
const LoadingSkeleton = () => {
  return (
    <div className="animate-pulse space-y-16">
      {/* Featured Post Skeleton */}
      <div className="bg-white/5 rounded-2xl p-8">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="aspect-[16/9] rounded-lg bg-white/10" />
          <div className="space-y-4">
            <div className="w-24 h-6 bg-white/10 rounded-full" />
            <div className="h-8 bg-white/10 rounded-lg w-3/4" />
            <div className="space-y-2">
              <div className="h-4 bg-white/10 rounded w-full" />
              <div className="h-4 bg-white/10 rounded w-5/6" />
              <div className="h-4 bg-white/10 rounded w-4/6" />
            </div>
          </div>
        </div>
      </div>

      {/* Latest Articles Grid Skeleton */}
      <div>
        <div className="h-8 bg-white/10 rounded w-48 mb-12" />
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {[1, 2, 3].map((n) => (
            <div key={n} className="bg-white/5 rounded-2xl p-6">
              <div className="aspect-[16/9] rounded-lg bg-white/10 mb-6" />
              <div className="h-6 bg-white/10 rounded w-3/4" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingSkeleton;
