
import { stripHtmlTags } from '../utils';

interface HeaderBlockProps {
  level?: number;
  text?: string;
}

const HeaderBlock = ({ level = 1, text = '' }: HeaderBlockProps) => {
  const HeaderTag = `h${level}` as keyof JSX.IntrinsicElements;
  return <HeaderTag>{stripHtmlTags(text)}</HeaderTag>;
};

export default HeaderBlock;
