
import { Button } from "@/components/ui/button";
import { Loader2, Trash2 } from "lucide-react";

interface DeleteButtonProps {
  isDeleting: boolean;
  isDisabled: boolean;
  onDelete: () => void;
}

const DeleteButton = ({ isDeleting, isDisabled, onDelete }: DeleteButtonProps) => {
  return (
    <Button 
      variant="destructive" 
      onClick={onDelete}
      disabled={isDisabled}
      className="inline-flex items-center"
    >
      {isDeleting ? (
        <Loader2 className="w-5 h-5 animate-spin mr-2" />
      ) : (
        <Trash2 className="w-5 h-5 mr-2" />
      )}
      Delete Image
    </Button>
  );
};

export default DeleteButton;
