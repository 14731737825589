
interface CodeBlockProps {
  code?: string;
}

const CodeBlock = ({ code = '' }: CodeBlockProps) => {
  return (
    <pre className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
      <code>{code}</code>
    </pre>
  );
};

export default CodeBlock;
