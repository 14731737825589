import { Button } from "@/components/ui/button";
import ArticlesTable from "./ArticlesTable";
import { CategoryManagement } from "./CategoryManagement";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const AdminDashboard = () => {
  const navigate = useNavigate();
  const [showDeleted, setShowDeleted] = useState(false);

  return (
    <div className="space-y-12 bg-white p-8 rounded-lg shadow-lg">
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-bold text-primary">Article Management</h1>
          <div className="flex gap-4">
            <Button 
              variant="outline"
              onClick={() => setShowDeleted(!showDeleted)}
            >
              {showDeleted ? "Show Active Articles" : "Show Deleted Articles"}
            </Button>
            <Button 
              onClick={() => navigate("/admin/articles/new")}
              className="bg-primary text-white hover:bg-primary-soft"
            >
              Create New Article
            </Button>
          </div>
        </div>
        <ArticlesTable showDeleted={showDeleted} />
      </div>

      <CategoryManagement />
    </div>
  );
};