
import { Link } from "react-router-dom";
import { Twitter, Linkedin, Instagram, Youtube } from "lucide-react";

const Footer = () => {
  const socialLinks = [
    { name: "Twitter", icon: Twitter, url: "#" },
    { name: "LinkedIn", icon: Linkedin, url: "https://linkedin.com/in/codymclain" },
    { name: "Instagram", icon: Instagram, url: "#" },
    { name: "YouTube", icon: Youtube, url: "https://www.youtube.com/@cody-mclain" },
  ];

  const mainLinks = [
    { name: "About", url: "/about" },
    { name: "Articles", url: "/articles" },
    { name: "Social", url: "/social" },
  ];

  return (
    <footer className="bg-accent py-8">
      <div className="container mx-auto px-6">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
          {/* Navigation Links */}
          <nav>
            <ul className="flex space-x-8">
              {mainLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.url}
                    className="text-primary-muted hover:text-primary transition-colors duration-200"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>

          {/* Social Links */}
          <div className="flex space-x-6">
            {socialLinks.map((link) => {
              const Icon = link.icon;
              return (
                <a
                  key={link.name}
                  href={link.url}
                  className="text-primary-muted hover:text-primary transition-colors duration-200"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={link.name}
                >
                  <Icon className="w-5 h-5" />
                </a>
              );
            })}
          </div>
        </div>

        {/* Copyright */}
        <div className="mt-8 pt-6 border-t border-border">
          <p className="text-center text-primary-muted text-sm">
            © {new Date().getFullYear()} Cody McLain. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
