
import { useEffect } from "react";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import ContactHeader from "@/components/ContactHeader";
import ContactForm from "@/components/ContactForm";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact | Cody McLain";
  }, []);

  return (
    <>
      <Navigation />
      <div className="min-h-screen bg-background">
        <div className="container max-w-4xl mx-auto px-6 py-24">
          <ContactHeader />
          <div className="max-w-xl mx-auto">
            <ContactForm />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
