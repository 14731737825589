
import { Star } from "lucide-react";
import { cn } from "@/lib/utils";

interface FeatureButtonProps {
  featured: boolean;
  onClick: () => void;
}

const FeatureButton = ({ featured, onClick }: FeatureButtonProps) => {
  return (
    <button 
      onClick={onClick}
      className="hover:scale-110 transition-transform"
    >
      <Star 
        className={cn(
          "h-5 w-5",
          featured 
            ? "fill-yellow-400 text-yellow-400" 
            : "text-gray-300"
        )} 
      />
    </button>
  );
};

export default FeatureButton;
