
import React from "react";

const LinksHero = () => {
  return (
    <div className="text-center mb-10">
      <h1 className="text-3xl font-serif mb-2 text-primary">Cody McLain</h1>
      <p className="text-primary-muted">Entrepreneur, Author, & Podcast Host</p>
    </div>
  );
};

export default LinksHero;
