import { supabase } from "@/integrations/supabase/client";
import { BlogPost } from "@/types";

export const fetchArticles = async (showDeleted: boolean) => {
  console.log("Fetching articles with showDeleted:", showDeleted);
  let query = supabase
    .from("blog_posts")
    .select(`
      *,
      categories (
        id,
        name
      )
    `);

  if (showDeleted) {
    query = query.eq("status", "deleted");
  } else {
    query = query.neq("status", "deleted");
  }

  const { data, error } = await query.order("created_on", { ascending: false });

  if (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }

  console.log("Fetched articles:", data);
  return data as BlogPost[];
};

export const handleArticleFeature = async (id: string) => {
  // First, unfeature all articles
  const { error: unfeaturedError } = await supabase
    .from("blog_posts")
    .update({ featured: false })
    .not('id', 'eq', id); // Changed to not affect the article we're featuring

  if (unfeaturedError) {
    throw new Error("Failed to unfeature current article");
  }

  // Then, feature the selected article
  const { error: featureError } = await supabase
    .from("blog_posts")
    .update({ featured: true })
    .eq("id", id);

  if (featureError) {
    throw new Error("Failed to feature article");
  }
};

export const handleArticleDelete = async (id: string) => {
  const { error } = await supabase
    .from("blog_posts")
    .update({
      status: "deleted",
      deleted_on: new Date().toISOString()
    })
    .eq("id", id);

  if (error) {
    throw new Error("Failed to delete article");
  }
};

export const handleArticleRecover = async (id: string) => {
  const { error } = await supabase
    .from("blog_posts")
    .update({
      status: "draft",
      deleted_on: null
    })
    .eq("id", id);

  if (error) {
    throw new Error("Failed to recover article");
  }
};

export const handleBulkStatusChange = async (ids: string[], newStatus: 'draft' | 'published') => {
  const { error } = await supabase
    .from("blog_posts")
    .update({
      status: newStatus,
      ...(newStatus === 'published' ? { published_on: new Date().toISOString() } : {})
    })
    .in('id', ids);

  if (error) {
    throw new Error("Failed to update article statuses");
  }
};

export const handleBulkDelete = async (ids: string[]) => {
  const { error } = await supabase
    .from("blog_posts")
    .update({
      status: "deleted",
      deleted_on: new Date().toISOString()
    })
    .in('id', ids);

  if (error) {
    throw new Error("Failed to delete articles");
  }
};

export const handleBulkRecover = async (ids: string[]) => {
  const { error } = await supabase
    .from("blog_posts")
    .update({
      status: "draft",
      deleted_on: null
    })
    .in('id', ids);

  if (error) {
    throw new Error("Failed to recover articles");
  }
};
