
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import Navigation from "@/components/Navigation";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import ArticleForm from "@/components/admin/ArticleForm";
import type { BlogPost } from "@/types";

const AdminArticleEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const isNew = id === "new";

  useEffect(() => {
    document.title = `${isNew ? "New Article" : "Edit Article"} | Admin | Cody McLain`;
  }, [isNew]);

  const { data: article, isLoading, error } = useQuery({
    queryKey: ["adminArticle", id],
    queryFn: async () => {
      if (isNew) {
        return null;
      }

      const { data, error } = await supabase
        .from("blog_posts")
        .select("*, categories (id, name)")
        .eq("id", id)
        .maybeSingle();

      if (error) {
        console.error("Supabase error fetching article:", error);
        throw error;
      }

      return data as BlogPost;
    },
  });

  const handleSubmit = async (formData: Partial<BlogPost>) => {
    try {
      if (!formData.title || !formData.slug) {
        throw new Error("Missing required fields");
      }

      const articleData = {
        title: formData.title,
        slug: formData.slug,
        content: formData.content || "",
        featured_image: formData.featured_image,
        status: formData.status || "draft",
        meta_description: formData.meta_description,
        image_attribution: formData.image_attribution,
        category_id: formData.category_id,
        updated_on: new Date().toISOString(),
      };

      let result;
      if (isNew) {
        result = await supabase
          .from("blog_posts")
          .insert([articleData])
          .select()
          .single();
      } else {
        result = await supabase
          .from("blog_posts")
          .update(articleData)
          .eq("id", id)
          .select()
          .single();
      }

      if (result.error) {
        throw result.error;
      }

      await queryClient.invalidateQueries({ queryKey: ["adminArticle", id] });
      await queryClient.invalidateQueries({ queryKey: ["articles"] });
      
      toast({
        title: "Success",
        description: `Article ${isNew ? "created" : "updated"} successfully`,
        variant: "default",
        className: "bg-green-500 text-white",
      });
      
      navigate("/admin");
    } catch (error) {
      console.error("Error saving article:", error);
      toast({
        title: "Error",
        description: `Failed to ${isNew ? "create" : "update"} article`,
        variant: "destructive",
      });
    }
  };

  if (error) {
    console.error("Error in article query:", error);
    return (
      <div className="min-h-screen bg-background">
        <Navigation />
        <div className="container mx-auto px-6 pt-32">
          <p className="text-red-500">Error loading article: {error.message}</p>
        </div>
      </div>
    );
  }

  if (!isNew && isLoading) {
    return (
      <div className="min-h-screen bg-background">
        <Navigation />
        <div className="container mx-auto px-6 pt-32">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <div className="container mx-auto px-6 pt-32">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-2xl font-bold">
            {isNew ? "Create New Article" : "Edit Article"}
          </h1>
          <Button 
            variant="outline" 
            onClick={() => navigate("/admin")}
          >
            Back to Articles
          </Button>
        </div>
        <ArticleForm initialData={article} onSubmit={handleSubmit} />
      </div>
    </div>
  );
};

export default AdminArticleEdit;
