
const AboutBook = () => {
  return (
    <section id="about" className="py-16 bg-white">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto">
          <h2 className="text-3xl font-avenir mb-8">About the Book</h2>
          <div className="prose prose-lg max-w-none">
            <p>
              In "From Foster Care to Millionaire," Cody McLain shares his extraordinary journey from a challenging childhood marked by loss and instability to becoming a successful entrepreneur and business leader. This inspiring memoir reveals how determination, resilience, and an unwavering drive for success can overcome even the most difficult circumstances.
            </p>
            <p>
              Starting his first business at just 15 years old while navigating the foster care system, Cody's story is a testament to the power of entrepreneurship as a path to personal transformation. Through raw and honest storytelling, he shares the strategies, mindset shifts, and life lessons that enabled him to build multiple successful businesses and find purpose in helping others achieve their dreams.
            </p>
            <p>
              This book is more than just a success story—it's a blueprint for anyone facing adversity who dreams of creating a better future for themselves. Whether you're an aspiring entrepreneur, overcoming personal challenges, or seeking inspiration to transform your life, Cody's journey offers valuable insights and practical wisdom for turning obstacles into opportunities.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBook;
