import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://tiundkelcnirafqhzgfv.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRpdW5ka2VsY25pcmFmcWh6Z2Z2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzcxNTM5NzUsImV4cCI6MjA1MjcyOTk3NX0.HXizO7zKqeIeEH0s_wOkEHDliDKWziY49oAHXfpy4L4";

export const supabase = createClient<Database>(
  SUPABASE_URL,
  SUPABASE_PUBLISHABLE_KEY,
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true
    },
    global: {
      headers: {
        'Content-Type': 'application/json',
        'x-client-info': '@supabase/javascript-client',
        'apikey': SUPABASE_PUBLISHABLE_KEY
      },
    },
    db: {
      schema: 'public'
    },
  }
);

// Add better error logging for debugging
void (async () => {
  try {
    console.log('Testing Supabase connection...');
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .limit(1);
    
    if (error) {
      console.error('Supabase test query error:', error);
      console.error('Error details:', {
        code: error.code,
        message: error.message,
        details: error.details
      });
    } else {
      console.log('Supabase connection successful', data);
    }
  } catch (error) {
    console.error('Supabase connection error:', error);
    if (error instanceof Error) {
      console.error('Error details:', {
        name: error.name,
        message: error.message,
        stack: error.stack
      });
    }
  }
})();