
export const convertHtmlToEditorJs = (html: string) => {
  if (!html) return { blocks: [] };

  // Remove any script tags for security
  html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

  // Split content by double newlines to separate blocks
  const blocks = html.split(/\n\n+/).filter(block => block.trim());

  return {
    blocks: blocks.map(block => {
      const trimmedBlock = block.trim();

      // Check if it's a header
      if (trimmedBlock.match(/^<h[1-6]>/i)) {
        const level = parseInt(trimmedBlock.match(/^<h([1-6])>/i)?.[1] || '2');
        return {
          type: 'header',
          data: {
            text: trimmedBlock.replace(/<\/?h[1-6]>/gi, '').trim(),
            level
          }
        };
      }

      // Check if it's an unordered list
      if (trimmedBlock.startsWith('<ul>')) {
        const items = trimmedBlock
          .replace(/<\/?ul>|<\/?li>/gi, '')
          .split(/\n/)
          .map(item => item.trim())
          .filter(Boolean);
        return {
          type: 'list',
          data: {
            style: 'unordered',
            items
          }
        };
      }

      // Check if it's an ordered list
      if (trimmedBlock.startsWith('<ol>')) {
        const items = trimmedBlock
          .replace(/<\/?ol>|<\/?li>/gi, '')
          .split(/\n/)
          .map(item => item.trim())
          .filter(Boolean);
        return {
          type: 'list',
          data: {
            style: 'ordered',
            items
          }
        };
      }

      // Check if it's an image
      if (trimmedBlock.match(/<img/i)) {
        const src = trimmedBlock.match(/src="([^"]+)"/i)?.[1];
        const alt = trimmedBlock.match(/alt="([^"]+)"/i)?.[1] || '';
        return {
          type: 'image',
          data: {
            url: src,
            caption: alt,
            withBorder: false,
            withBackground: false,
            stretched: false
          }
        };
      }

      // Default to paragraph
      return {
        type: 'paragraph',
        data: {
          text: trimmedBlock.replace(/<\/?p>/gi, '').trim()
        }
      };
    })
  };
};
