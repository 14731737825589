
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import Navigation from "@/components/Navigation";
import Hero from "@/components/Hero";
import ArticleCard from "@/components/ArticleCard";
import NewsletterSection from "@/components/NewsletterSection";
import Footer from "@/components/Footer";
import { supabase } from "@/integrations/supabase/client";

const Index = () => {
  useEffect(() => {
    document.title = "Cody McLain - Optimize for Happiness";
  }, []);

  const { data: articles, isLoading } = useQuery({
    queryKey: ["latestArticles"],
    queryFn: async () => {
      console.log("Fetching latest articles for homepage");
      const { data, error } = await supabase
        .from("blog_posts")
        .select("*")
        .eq("status", "published")
        .order("published_on", { ascending: false })
        .limit(3);

      if (error) {
        console.error("Error fetching latest articles:", error);
        throw error;
      }
      
      console.log("Latest articles:", data);
      return data;
    },
  });

  return (
    <div className="min-h-screen bg-[#233144]">
      <Navigation />
      <Hero />
      
      <section className="py-24 bg-accent-soft">
        <div className="container mx-auto px-6">
          <div className="text-center mb-16">
            <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-white rounded-full">
              Latest Articles
            </span>
            <h2 className="text-4xl">Insights & Reflections</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {isLoading ? (
              [...Array(3)].map((_, i) => (
                <div
                  key={i}
                  className="bg-white rounded-2xl overflow-hidden"
                >
                  <div className="aspect-[16/9] bg-gray-200 animate-pulse" />
                  <div className="p-6">
                    <div className="h-6 bg-gray-200 rounded w-3/4 animate-pulse" />
                  </div>
                </div>
              ))
            ) : articles?.length ? (
              articles.map((article) => (
                <ArticleCard
                  key={article.id}
                  title={article.title}
                  featured_image={article.featured_image}
                  slug={article.slug}
                />
              ))
            ) : (
              <div className="col-span-3 text-center text-gray-500">
                No articles found
              </div>
            )}
          </div>
        </div>
      </section>

      <NewsletterSection />
      <Footer />
    </div>
  );
};

export default Index;
