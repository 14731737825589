
import { useState } from "react";
import { BlogPost } from "@/types";

export type SortField = "status" | "created_on" | null;
export type SortDirection = "asc" | "desc";

export const useArticleSort = (articles: BlogPost[] | undefined) => {
  const [sortField, setSortField] = useState<SortField>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>("asc");

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const sortedArticles = articles ? [...articles].sort((a, b) => {
    if (!sortField) return 0;

    if (sortField === "status") {
      const statusA = (a.status || "draft").toLowerCase();
      const statusB = (b.status || "draft").toLowerCase();
      return sortDirection === "asc" 
        ? statusA.localeCompare(statusB)
        : statusB.localeCompare(statusA);
    }

    if (sortField === "created_on") {
      const dateA = new Date(a.created_on || 0).getTime();
      const dateB = new Date(b.created_on || 0).getTime();
      return sortDirection === "asc" ? dateA - dateB : dateB - dateA;
    }

    return 0;
  }) : [];

  return {
    sortField,
    sortDirection,
    handleSort,
    sortedArticles
  };
};
