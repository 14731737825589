
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

const NewsletterSection = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { error } = await supabase.functions.invoke('subscribe-sendfox', {
        body: { email }
      });

      if (error) throw error;

      toast({
        title: "Thank you for subscribing!",
        description: "You'll receive updates from me soon.",
      });
      setEmail("");
    } catch (error) {
      console.error('Newsletter subscription error:', error);
      toast({
        title: "Error",
        description: "There was a problem subscribing you. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="py-24 bg-warm-light">
      <div className="container mx-auto px-6">
        <div className="max-w-xl mx-auto text-center">
          <h2 className="text-3xl font-avenir mb-4">Stay Updated</h2>
          <p className="text-primary-muted mb-8">
            Join my newsletter for insights on productivity, mindfulness, and
            purposeful living.
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="glass-effect p-2 rounded-lg flex">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                className="flex-1 bg-transparent px-4 py-2 outline-none"
                required
                disabled={isSubmitting}
              />
              <button
                type="submit"
                className="px-6 py-2 bg-primary text-white rounded-lg hover-lift disabled:opacity-50"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Subscribing..." : "Subscribe"}
              </button>
            </div>
            <p className="text-sm text-primary-muted">
              By subscribing, you agree to receive updates. Unsubscribe anytime.
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSection;
