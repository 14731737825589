
import { FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import type { UseFormSetValue } from "react-hook-form";
import type { BlogPost } from "@/types";
import UploadButton from "./image-upload/UploadButton";
import DeleteButton from "./image-upload/DeleteButton";
import ImagePreview from "./image-upload/ImagePreview";
import { useImageUpload } from "./image-upload/useImageUpload";

interface ImageUploadProps {
  value?: string;
  setValue: UseFormSetValue<Partial<BlogPost>>;
}

const ImageUpload = ({ value, setValue }: ImageUploadProps) => {
  const {
    uploading,
    verifying,
    deleting,
    handleDelete,
    handleUpload
  } = useImageUpload(value, setValue);

  return (
    <FormItem>
      <FormLabel>Featured Image</FormLabel>
      <div className="space-y-4">
        <div className="flex gap-2">
          <UploadButton
            isUploading={uploading}
            isVerifying={verifying}
            isDeleting={deleting}
            onFileSelect={handleUpload}
          />
          {value && value !== '' && (
            <DeleteButton
              isDeleting={deleting}
              isDisabled={uploading || verifying || deleting}
              onDelete={handleDelete}
            />
          )}
        </div>
        <p className="text-sm text-gray-500">
          Maximum file size: 5MB. Supported formats: JPG, PNG, GIF, WebP
        </p>
        {value && value !== '' && (
          <ImagePreview src={value} />
        )}
      </div>
      <FormMessage />
    </FormItem>
  );
};

export default ImageUpload;
