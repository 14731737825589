
import { type Control, type UseFormSetValue } from "react-hook-form";
import { type BlogPost } from "@/types";
import { Button } from "@/components/ui/button";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import ImageUpload from "./ImageUpload";

interface SidebarProps {
  control: Control<Partial<BlogPost>>;
  setValue: UseFormSetValue<Partial<BlogPost>>;
  isEditing: boolean;
}

const Sidebar = ({ control, setValue, isEditing }: SidebarProps) => {
  return (
    <div className="space-y-6 pb-20 md:pb-6">
      <div className="sticky top-4">
        <FormField
          control={control}
          name="featured_image"
          render={({ field }) => (
            <ImageUpload 
              value={field.value} 
              setValue={setValue}
            />
          )}
        />

        <FormField
          control={control}
          name="image_attribution"
          render={({ field }) => (
            <FormItem className="mt-2">
              <FormLabel>Image Attribution</FormLabel>
              <FormControl>
                <Input {...field} placeholder="e.g., Photo by John Doe on Unsplash" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button 
          type="submit" 
          className="w-full mt-6 bg-primary text-white hover:bg-primary/90"
        >
          {isEditing ? "Update Article" : "Create Article"}
        </Button>
      </div>
    </div>
  );
};

export default Sidebar;
