
import { TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";

interface ArticlesTableHeaderProps {
  onSelectAll: (checked: boolean) => void;
  onSort?: (field: string) => void;
  allSelected: boolean;
  hasArticles: boolean;
}

const ArticlesTableHeader = ({
  onSelectAll,
  allSelected,
  hasArticles,
}: ArticlesTableHeaderProps) => {
  return (
    <TableHeader>
      <TableRow>
        <TableHead className="w-12">
          <Checkbox
            checked={hasArticles && allSelected}
            onCheckedChange={(checked) => onSelectAll(checked as boolean)}
            className="border-primary data-[state=checked]:border-primary data-[state=checked]:bg-white"
          />
        </TableHead>
        <TableHead className="w-12">Featured</TableHead>
        <TableHead>Title</TableHead>
        <TableHead>Category</TableHead>
        <TableHead>Status</TableHead>
        <TableHead>Dates</TableHead>
      </TableRow>
    </TableHeader>
  );
};

export default ArticlesTableHeader;
