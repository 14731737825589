import { ArrowRight } from "lucide-react";
import NewsletterSection from "@/components/NewsletterSection";

const StorySection = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <div className="animate-on-scroll prose text-left">
            {/* Introduction */}
            <div className="mb-12">
              <h1 className="text-4xl font-serif mb-6 text-primary">About Cody McLain</h1>
              <p className="text-xl leading-relaxed text-primary-muted">
                Cody McLain is an entrepreneur, author, podcast host, and self-improvement advocate who rose from a childhood of foster care and poverty to build a multimillion-dollar company employing over 1,000 people. He founded his first business as a teenager and has since launched several successful ventures. Through his journey, Cody's mission has been to prove that personal growth and success are achievable for anyone willing to learn continuously and persevere against the odds. He blends an inspiring personal story with professional insights, sharing how determination and learning transformed his life.
              </p>
            </div>

            {/* Early Life Section */}
            <div className="mb-12">
              <h2 className="text-3xl font-serif mb-4 text-primary">Early Life and Challenges</h2>
              <div className="bg-warm/50 p-6 rounded-lg mb-6">
                <p className="text-lg italic text-primary-muted">
                  "I wanted to prove to those rich kids that I could be better than them."
                </p>
              </div>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                Cody's early life was marked by significant hardship. Born to parents who struggled with alcoholism, he lost his father at a young age and entered foster care while his mother went into rehab. He grew up with scarce financial resources – at one point living on social security benefits – and often felt the sting of poverty while surrounded by more privileged peers. Bullied in school and feeling out of place, Cody developed a deep drive to prove himself.
              </p>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                At just 15 years old, Cody decided to take control of his future by starting a business. After a classmate suggested the idea of a web hosting venture (and quickly abandoned it), Cody pushed forward on his own – teaching himself the technical and business skills needed to run a hosting company. Working from a foster home environment, he poured his energy into this project.
              </p>
              <div className="bg-accent-soft p-6 rounded-lg mb-4">
                <p className="text-lg italic text-primary-muted">
                  "How I became a successful entrepreneur, it turned out, wasn't separable from how I became who I am."
                </p>
              </div>
            </div>

            {/* Entrepreneurial Journey */}
            <div className="mb-12">
              <h2 className="text-3xl font-serif mb-4 text-primary">Entrepreneurial Journey</h2>
              <p className="text-lg leading-relaxed text-primary-muted mb-6">
                Building his first company was a formative experience for Cody. By the time he was 18, his small hosting business was generating almost $1 million in annual revenue. This early success validated his belief that he could rise above his upbringing. However, the road was not without setbacks. In his late teens, Cody faced a devastating blow when a fraudulent business partner defrauded him – resulting in the loss of the company he had spent years building.
              </p>
              <p className="text-lg leading-relaxed text-primary-muted mb-6">
                With just $70k and no clear direction, Cody's response was persistence: he decided to start over and apply the hard lessons he'd learned. In his early twenties, unable to find a traditional job and armed only with the knowledge gained from his failure, Cody founded a new company. This next venture took off rapidly – it became profitable within just three months and even surpassed the success of his first business.
              </p>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                <div className="bg-accent-soft p-6 rounded-lg">
                  <p className="font-medium text-primary mb-2">Early Success</p>
                  <p className="text-primary-muted">First company reached $1M revenue by age 18</p>
                </div>
                <div className="bg-accent-soft p-6 rounded-lg">
                  <p className="font-medium text-primary mb-2">Rapid Growth</p>
                  <p className="text-primary-muted">SupportNinja: 600% growth in 5 years</p>
                </div>
                <div className="bg-accent-soft p-6 rounded-lg">
                  <p className="font-medium text-primary mb-2">Current Success</p>
                  <p className="text-primary-muted">$24.5M revenue in 2021</p>
                </div>
              </div>
            </div>

            {/* Building SupportNinja */}
            <div className="mb-12">
              <h2 className="text-3xl font-serif mb-4 text-primary">Building a Multimillion-Dollar Company</h2>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                Cody's determination culminated in the creation of SupportNinja, an outsourcing company he founded to help startups with customer support and back-office tasks. What began as another small startup grew into a multimillion-dollar enterprise with a global footprint. Today, SupportNinja serves major clients like the Bill & Melinda Gates Foundation and employs over 1,000 people worldwide.
              </p>
              <div className="bg-warm/50 p-6 rounded-lg mb-6">
                <h3 className="text-xl font-serif mb-3 text-primary">Notable Achievements</h3>
                <ul className="list-disc list-inside space-y-2 text-primary-muted">
                  <li>Founded first company at 15 and built several successful ventures</li>
                  <li>Grew SupportNinja to $25 million ARR by his mid-20s</li>
                  <li>Named one of Startup Weekly's "Founders to Watch" in 2019</li>
                  <li>Member of the Young Entrepreneur Council</li>
                  <li>Published author of "From Foster Care to Millionaire"</li>
                  <li>Achieved success without a college degree</li>
                </ul>
              </div>
            </div>

            {/* Personal Growth */}
            <div className="mb-12">
              <h2 className="text-3xl font-serif mb-4 text-primary">Mission of Learning and Personal Growth</h2>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                Despite his outward success, Cody realized at one point that working 12-hour days and focusing solely on business had left him burned out and unfulfilled. This realization prompted a shift in how he defined success. He began studying productivity hacks, health, and mindfulness – reading everything from Getting Things Done by David Allen to Stoic philosophy like Marcus Aurelius's Meditations.
              </p>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                Beyond business skills, Cody pushed himself outside of his comfort zone. He earned his private pilot's license, became an avid photographer, and traveled the world. These personal achievements became as important to him as his companies. Today, Cody strives to maintain a healthy balance between personal development, work, and leisure.
              </p>
              <blockquote className="border-l-4 border-warm pl-6 my-6">
                <p className="text-xl font-serif italic text-primary">
                  "Your potential can far exceed your expectations."
                </p>
              </blockquote>
            </div>

            {/* Podcast and Public Speaking */}
            <div className="mb-12">
              <h2 className="text-3xl font-serif mb-4 text-primary">Podcast Host and Public Speaker</h2>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                To spread his message and insights, Cody hosts the MindHack Podcast, exploring routines and mindsets that drive success. Each episode features conversations with experts in fields like business, psychology, and productivity. The podcast's central theme is "achieving our goals, doing more with less, and learning how to change the world with big ideas" – reflecting Cody's own values of efficiency and impactful thinking.
              </p>
            </div>

            {/* Personal Passions */}
            <div className="mb-12">
              <h2 className="text-3xl font-serif mb-4 text-primary">Personal Passions and Current Ventures</h2>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                Despite juggling multiple roles as a founder and content creator, Cody makes time for his personal passions. He is a licensed pilot, often taking to the skies for the sheer joy of flying. As a travel photographer, he has visited countries around the globe, capturing breathtaking images and embracing new experiences. He's also a certified scuba diver, adding underwater exploration to his list of adventures.
              </p>
              <p className="text-lg leading-relaxed text-primary-muted mb-4">
                Based in Austin, Texas, Cody has successfully exited SupportNinja and is now focused on new ventures. He is currently working on a book about working with business partners, drawing from his extensive experience in building and scaling companies. Additionally, he is launching an innovative AI coaching platform, combining his entrepreneurial expertise with cutting-edge technology to help others achieve their goals.
              </p>
              <p className="text-lg leading-relaxed text-primary-muted">
                He continues to write thought leadership articles, contribute to platforms like Forbes and the Austin Business Journal, and engage with an online community through his blog and social media.
              </p>
            </div>

            {/* Final Quote */}
            <blockquote className="border-l-4 border-warm pl-6 my-12">
              <p className="text-2xl font-serif italic text-primary">
                "Follow your dreams, your passions – your obsessions. Persistence will always turn failure into success."
              </p>
              <footer className="text-primary-muted mt-2">- Cody McLain</footer>
            </blockquote>

            {/* Newsletter Subscription */}
            <div className="bg-warm p-8 rounded-lg">
              <h3 className="text-2xl font-serif mb-4 text-primary">Join Cody's Newsletter</h3>
              <p className="text-lg mb-6 text-primary-muted">
                Subscribe to receive insights on productivity, mindfulness, and purposeful living. Learn from Cody's journey and get updates on his upcoming book and AI coaching platform.
              </p>
              <NewsletterSection />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StorySection;
