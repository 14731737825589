import { useEffect, useRef } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import List from '@editorjs/list';
import Paragraph from '@editorjs/paragraph';
import ImageTool from '@editorjs/image';
import LinkTool from '@editorjs/link';
import Code from '@editorjs/code';
import Delimiter from '@editorjs/delimiter';
import Embed from '@editorjs/embed';
import InlineCode from '@editorjs/inline-code';
import Marker from '@editorjs/marker';
import Quote from '@editorjs/quote';
import Raw from '@editorjs/raw';
import Table from '@editorjs/table';
import Warning from '@editorjs/warning';
import CheckList from '@editorjs/checklist';
import { supabase } from '@/integrations/supabase/client';
import { convertHtmlToEditorJs } from '@/utils/htmlToEditorJs';

interface EditorWrapperProps {
  value: string;
  onChange: (content: string) => void;
  height?: number;
}

const EditorWrapper = ({ value, onChange, height = 400 }: EditorWrapperProps) => {
  const editorRef = useRef<EditorJS | null>(null);
  const holderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!holderRef.current) return;

    let initialData;
    try {
      console.log('EditorWrapper initializing with value:', value);
      
      // Try to parse the content as JSON
      try {
        initialData = value ? JSON.parse(value) : undefined;
        console.log('Successfully parsed JSON data:', initialData);
      } catch (parseError) {
        console.log('Value is not JSON, converting from HTML:', value);
        initialData = convertHtmlToEditorJs(value);
        console.log('Converted HTML to EditorJS format:', initialData);
      }
      
      // Validate the structure and ensure it has the correct format
      if (!initialData || !initialData.blocks || !Array.isArray(initialData.blocks)) {
        console.log('Creating new editor data structure');
        initialData = {
          time: new Date().getTime(),
          blocks: []
        };
      } else {
        // Ensure each block has an id
        initialData.blocks = initialData.blocks.map((block: any) => ({
          id: block.id || `block-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          type: block.type || 'paragraph',
          data: block.data || { text: '' }
        }));
      }
    } catch (e) {
      console.error('Error processing initial data:', e);
      initialData = {
        time: new Date().getTime(),
        blocks: []
      };
    }

    console.log('Final initial data for editor:', initialData);

    const editor = new EditorJS({
      holder: holderRef.current,
      data: initialData,
      onChange: async () => {
        try {
          const content = await editor.save();
          console.log('Editor content before save:', content);
          console.log('Individual blocks:');
          content.blocks.forEach((block: any, index: number) => {
            console.log(`Block ${index}:`, block);
          });
          onChange(JSON.stringify(content));
        } catch (error) {
          console.error('Error saving editor content:', error);
        }
      },
      autofocus: true,
      placeholder: 'Start writing your content...',
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true
          }
        },
        header: {
          class: Header,
          config: {
            levels: [1, 2, 3, 4, 5, 6],
            defaultLevel: 2
          },
          inlineToolbar: true
        },
        delimiter: Delimiter,
        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: 'unordered'
          }
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile: async (file: File) => {
                console.log('Uploading image file:', file.name);
                const fileName = `${crypto.randomUUID()}.${file.name.split('.').pop()}`;
                const { data, error } = await supabase.storage
                  .from('blog-images')
                  .upload(fileName, file);

                if (error) {
                  console.error('Error uploading image:', error);
                  throw new Error('Failed to upload image');
                }

                const { data: { publicUrl } } = supabase.storage
                  .from('blog-images')
                  .getPublicUrl(fileName);

                console.log('Image uploaded successfully:', publicUrl);
                return {
                  success: 1,
                  file: {
                    url: publicUrl
                  }
                };
              }
            }
          }
        },
        link: {
          class: LinkTool,
          inlineToolbar: true
        },
        code: {
          class: Code,
          inlineToolbar: true
        },
        embed: {
          class: Embed,
          config: {
            services: {
              youtube: true,
              coub: true,
              codepen: true,
              pinterest: true,
              vimeo: true
            }
          }
        },
        inlineCode: {
          class: InlineCode,
          shortcut: 'CMD+SHIFT+M'
        },
        marker: {
          class: Marker,
          shortcut: 'CMD+SHIFT+H'
        },
        quote: {
          class: Quote,
          inlineToolbar: true,
          config: {
            quotePlaceholder: 'Enter a quote',
            captionPlaceholder: 'Quote\'s author'
          },
          shortcut: 'CMD+SHIFT+O'
        },
        raw: Raw,
        table: Table as any,
        warning: {
          class: Warning,
          inlineToolbar: true,
          config: {
            titlePlaceholder: 'Title',
            messagePlaceholder: 'Message'
          }
        },
        checklist: {
          class: CheckList,
          inlineToolbar: true
        }
      },
    });

    editorRef.current = editor;

    return () => {
      if (editorRef.current) {
        console.log('Cleaning up editor instance');
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, []);

  return (
    <div 
      ref={holderRef} 
      style={{ 
        minHeight: `${height}px`,
        border: '1px solid #e2e8f0',
        borderRadius: '0.375rem',
        padding: '1rem'
      }}
    />
  );
};

export default EditorWrapper;
