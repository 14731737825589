
import { stripHtmlTags } from '../utils';

interface ChecklistItem {
  text: string;
  checked: boolean;
}

interface ChecklistBlockProps {
  items?: ChecklistItem[];
}

const ChecklistBlock = ({ items = [] }: ChecklistBlockProps) => {
  return (
    <div className="space-y-2 my-4">
      {Array.isArray(items) && items.map((item, i) => (
        <div key={i} className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={item.checked}
            readOnly
            className="h-4 w-4 rounded border-gray-300"
          />
          <span className={item.checked ? 'line-through text-gray-500' : ''}>
            {stripHtmlTags(item.text)}
          </span>
        </div>
      ))}
    </div>
  );
};

export default ChecklistBlock;
