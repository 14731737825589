const ErrorMessage = () => {
  return (
    <div className="text-white bg-red-500/20 p-6 rounded-lg">
      <p className="text-lg mb-2">Unable to load articles at this time.</p>
      <p className="text-sm opacity-80">
        Please try refreshing the page. If the problem persists, check back later.
      </p>
    </div>
  );
};

export default ErrorMessage;