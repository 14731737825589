
import { Loader2, Upload } from "lucide-react";

interface UploadButtonProps {
  isUploading: boolean;
  isVerifying: boolean;
  isDeleting: boolean;
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadButton = ({ 
  isUploading, 
  isVerifying, 
  isDeleting, 
  onFileSelect 
}: UploadButtonProps) => {
  return (
    <label className="cursor-pointer inline-flex items-center bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary/90 transition-colors">
      {isUploading || isVerifying ? (
        <>
          <Loader2 className="w-5 h-5 animate-spin mr-2" />
          {isVerifying ? 'Verifying...' : 'Uploading...'}
        </>
      ) : (
        <>
          <Upload className="w-5 h-5 inline-block mr-2" />
          Upload Image
        </>
      )}
      <input
        type="file"
        className="hidden"
        accept="image/jpeg,image/png,image/gif,image/webp"
        onChange={onFileSelect}
        disabled={isUploading || isVerifying || isDeleting}
      />
    </label>
  );
};

export default UploadButton;
