
import { BlogPost } from "@/types";
import { TableCell, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { ExternalLink } from "lucide-react";
import { Link } from "react-router-dom";
import { getStatusStyles } from "../utils/statusStyles";
import FeatureButton from "./FeatureButton";
import { format } from "date-fns";

interface ArticleTableRowProps {
  article: BlogPost;
  selected: boolean;
  onSelect: (id: string, checked: boolean) => void;
  onFeatureToggle: (id: string) => void;
}

const ArticleTableRow = ({
  article,
  selected,
  onSelect,
  onFeatureToggle
}: ArticleTableRowProps) => {
  const formatDate = (date: string | undefined) => {
    if (!date) return '-';
    return format(new Date(date), 'MMM d, yyyy');
  };

  return (
    <TableRow className="data-[state=selected]:bg-muted/50">
      <TableCell>
        <Checkbox 
          checked={selected}
          onCheckedChange={(checked) => onSelect(article.id, checked as boolean)}
          className="border-primary data-[state=checked]:border-primary data-[state=checked]:bg-white"
        />
      </TableCell>
      <TableCell>
        <FeatureButton 
          featured={article.featured || false}
          onClick={() => onFeatureToggle(article.id)}
        />
      </TableCell>
      <TableCell className="font-medium">
        <div className="flex items-center gap-2">
          <Link 
            to={`/admin/articles/${article.id}`}
            className="text-primary hover:text-primary-muted transition-colors"
          >
            {article.title}
          </Link>
          {article.status === 'published' && (
            <Link 
              to={`/articles/${article.slug}`}
              className="text-gray-400 hover:text-primary transition-colors"
              title="View published article"
              target="_blank"
            >
              <ExternalLink className="h-4 w-4" />
            </Link>
          )}
        </div>
      </TableCell>
      <TableCell>{article.categories?.name || 'Uncategorized'}</TableCell>
      <TableCell>
        <span className={getStatusStyles(article.status || 'draft')}>
          {article.status || 'DRAFT'}
        </span>
      </TableCell>
      <TableCell className="space-y-1">
        <div className="text-sm text-muted-foreground">
          Created: {formatDate(article.created_on)}
        </div>
        {article.status === 'published' && (
          <div className="text-sm text-muted-foreground">
            Published: {formatDate(article.published_on)}
          </div>
        )}
      </TableCell>
    </TableRow>
  );
};

export default ArticleTableRow;
