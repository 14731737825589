
import { useEffect, useRef } from "react";

const Hero = () => {
  const headingRef = useRef<HTMLHeadingElement>(null);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) observer.observe(headingRef.current);
    if (textRef.current) observer.observe(textRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <section className="w-full h-screen bg-warm">
      <div className="container mx-auto px-6 h-full flex items-center justify-center">
        <div className="text-center">
          <div className="w-32 h-32 mx-auto mb-8 rounded-full overflow-hidden border-4 border-white shadow-lg">
            <img
              src="/lovable-uploads/098ef8bd-5d97-4d8e-b984-55ef6e050f00.png"
              alt="Profile"
              className="w-full h-full object-cover object-[center_42%]"
            />
          </div>
          <span className="inline-block px-4 py-1.5 mb-6 text-sm font-medium bg-warm-light rounded-full animate-fade-in">
            Welcome to My Journey
          </span>
          <h1
            ref={headingRef}
            className="animate-on-scroll text-5xl md:text-7xl font-avenir font-bold mb-6 leading-tight"
          >
            Optimize for
            <br />
            <span className="text-primary">Happiness</span>
          </h1>
          <p
            ref={textRef}
            className="animate-on-scroll text-lg md:text-xl text-primary-muted max-w-2xl mx-auto mb-8"
          >
            Serial entrepreneur and host of the MindHack podcast, sharing insights on
            building purposeful lives through mindful living and strategic thinking.
          </p>
          <div className="flex justify-center space-x-4 animate-fade-in">
            <button className="px-8 py-3 bg-primary text-white rounded-lg hover-lift">
              Read Articles
            </button>
            <a 
              href="https://www.mindhack.com" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="px-8 py-3 bg-accent text-primary rounded-lg hover-lift"
            >
              Listen to Podcast
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
