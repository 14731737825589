
import { stripHtmlTags } from '../utils';

interface WarningBlockProps {
  title?: string;
  message?: string;
}

const WarningBlock = ({ title, message = '' }: WarningBlockProps) => {
  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4">
      {title && (
        <h4 className="text-yellow-800 font-bold mb-2">{stripHtmlTags(title)}</h4>
      )}
      <p className="text-yellow-700">{stripHtmlTags(message)}</p>
    </div>
  );
};

export default WarningBlock;
