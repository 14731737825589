
import { Button } from "@/components/ui/button";
import { BookOpen, Headphones } from "lucide-react";

const AuthorStory = () => {
  return (
    <section id="author" className="py-16 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8 items-center">
            <div>
              <img
                src="/lovable-uploads/2e5ab205-4a03-4ee1-9750-0444bd46365d.png"
                alt="From Foster Care to Millionaire Book Cover"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="space-y-6">
              <h2 className="text-3xl font-serif mb-4">Get Your Copy Today</h2>
              <p className="text-gray-600 mb-6">
                Available in hardcover, Kindle, and audiobook formats. Choose your preferred way to experience this inspiring journey from foster care to entrepreneurial success.
              </p>
              
              <div className="space-y-4">
                <Button
                  className="w-full bg-[#FF9900] hover:bg-[#FF9900]/90 text-white"
                  onClick={() => window.open('https://www.amazon.com/Foster-Care-Millionaire-Entrepreneurs-Tragedy-ebook/dp/B07K8SDJR8?&linkCode=ll1&tag=mindhack-20&linkId=a69e1d840f2c58c71f4daaaca821691e&language=en_US&ref_=as_li_ss_tl', '_blank')}
                >
                  <BookOpen className="mr-2 h-4 w-4" />
                  Buy on Amazon
                </Button>
                
                <Button
                  className="w-full bg-[#14161A] hover:bg-[#14161A]/90 text-white"
                  onClick={() => window.open('https://www.audible.com/pd/From-Foster-Care-to-Millionaire-Audiobook/B07WP43M8W', '_blank')}
                >
                  <Headphones className="mr-2 h-4 w-4" />
                  Listen on Audible
                </Button>
              </div>

              <div className="mt-6 p-4 bg-white rounded-lg shadow-sm">
                <p className="text-sm text-gray-500">
                  "From Foster Care to Millionaire" is available worldwide. Experience the journey that proves success is possible regardless of where you start in life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuthorStory;
