
import { useEffect } from "react";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import BookHero from "@/components/book/BookHero";
import AboutBook from "@/components/book/AboutBook";
import AuthorStory from "@/components/book/AuthorStory";
import FreeSample from "@/components/book/FreeSample";
import Reviews from "@/components/book/Reviews";
import CallToAction from "@/components/book/CallToAction";

const Book = () => {
  useEffect(() => {
    document.title = "From Foster Care to Millionaire | Cody McLain";
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <Navigation />
      <main>
        <BookHero />
        <AboutBook />
        <AuthorStory />
        <FreeSample />
        <Reviews />
        <CallToAction />
      </main>
      <Footer />
    </div>
  );
};

export default Book;
