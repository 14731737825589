
import { Star } from "lucide-react";

const reviews = [
  {
    text: "Cody's story had me captivated early on... It's an amazing story of how someone can be successful no matter what cards he's dealt.",
    author: "Sarah Anne Carter",
    rating: 5
  },
  {
    text: "An inspiring journey that shows the true power of determination and entrepreneurial spirit. A must-read for anyone facing adversity.",
    author: "Michael R. Thompson",
    rating: 5
  },
  {
    text: "Raw, honest, and deeply moving. McLain's story is a testament to the human spirit and the power of perseverance.",
    author: "Jennifer Blake",
    rating: 5
  }
];

const Reviews = () => {
  return (
    <section id="reviews" className="py-16 bg-gray-50">
      <div className="container mx-auto px-6">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-3xl font-serif mb-12 text-center">What Readers Are Saying</h2>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
            {reviews.map((review, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex mb-4">
                  {[...Array(review.rating)].map((_, i) => (
                    <Star key={i} className="w-5 h-5 text-yellow-400 fill-current" />
                  ))}
                </div>
                <p className="text-gray-600 mb-4">"{review.text}"</p>
                <p className="font-medium text-primary">{review.author}</p>
              </div>
            ))}
          </div>

          <div className="text-center space-y-4">
            <div className="inline-flex items-center space-x-4">
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="w-6 h-6 text-yellow-400 fill-current" />
                ))}
              </div>
              <span className="text-lg font-medium">4.8 out of 5 on Amazon</span>
            </div>
            <p className="text-gray-500">Based on 50+ reviews across platforms</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
