
import { stripHtmlTags } from '../utils';

interface TableBlockProps {
  content?: string[] | string[][];
}

const TableBlock = ({ content = [] }: TableBlockProps) => {
  // Ensure content is always a 2D array
  const tableContent = Array.isArray(content) && content.length > 0
    ? (Array.isArray(content[0]) ? content as string[][] : [content as string[]])
    : [[]];

  return (
    <div className="overflow-x-auto my-4">
      <table className="min-w-full">
        <tbody>
          {tableContent.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="border px-4 py-2">
                  {stripHtmlTags(cell)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableBlock;

