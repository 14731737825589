
import React from "react";
import { Youtube, Instagram, Twitter } from "lucide-react";
import { cn } from "@/lib/utils";

type SocialLinkProps = {
  platform: string;
  icon: React.ElementType;
  username: string;
  url: string;
  gradientFrom: string;
  gradientVia: string;
  gradientTo: string;
};

const SocialLink = ({ platform, icon: Icon, username, url, gradientFrom, gradientVia, gradientTo }: SocialLinkProps) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center gap-3 hover:bg-gray-100 p-2 rounded-lg transition-colors"
    >
      <div className={cn(
        "relative p-2 rounded-full overflow-hidden",
        "flex items-center justify-center"
      )}>
        <div className={cn(
          "absolute inset-0",
          `bg-gradient-to-r ${gradientFrom} ${gradientVia} ${gradientTo}`,
          "opacity-80"
        )} />
        <Icon className="w-5 h-5 relative z-10 text-white" />
      </div>
      <div className="text-sm">
        <div className="text-primary font-medium">{platform}</div>
        <div className="text-primary-muted text-xs">{username}</div>
      </div>
    </a>
  );
};

const SocialIcons = () => {
  const personalLinks = [
    {
      platform: "YouTube",
      icon: Youtube,
      username: "@cody-mclain",
      url: "https://www.youtube.com/@cody-mclain",
      gradientFrom: "from-orange-500",
      gradientVia: "via-amber-500", 
      gradientTo: "to-yellow-500"
    },
    {
      platform: "Instagram",
      icon: Instagram,
      username: "@codysview",
      url: "https://instagram.com/codysview",
      gradientFrom: "from-purple-500",
      gradientVia: "via-fuchsia-500", 
      gradientTo: "to-pink-500"
    },
    {
      platform: "Twitter/X",
      icon: Twitter,
      username: "@codymclain",
      url: "https://twitter.com/codymclain",
      gradientFrom: "from-blue-500",
      gradientVia: "via-cyan-500", 
      gradientTo: "to-sky-400"
    }
  ];

  const mindhackLinks = [
    {
      platform: "YouTube",
      icon: Youtube,
      username: "@mindhackpod",
      url: "https://www.youtube.com/@mindhackpod",
      gradientFrom: "from-orange-500",
      gradientVia: "via-amber-500", 
      gradientTo: "to-yellow-500"
    },
    {
      platform: "Instagram",
      icon: Instagram,
      username: "@mindhack.podcast",
      url: "https://instagram.com/mindhack.podcast",
      gradientFrom: "from-purple-500",
      gradientVia: "via-fuchsia-500", 
      gradientTo: "to-pink-500"
    }
  ];

  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-primary font-medium mb-3 text-sm uppercase tracking-wider">
          Personal
        </h3>
        <div className="space-y-1">
          {personalLinks.map((link, index) => (
            <SocialLink key={index} {...link} />
          ))}
        </div>
      </div>
      
      <div>
        <h3 className="text-primary font-medium mb-3 text-sm uppercase tracking-wider">
          MindHack Podcast
        </h3>
        <div className="space-y-1">
          {mindhackLinks.map((link, index) => (
            <SocialLink key={index} {...link} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SocialIcons;
