import { useEffect } from "react";

const RECAPTCHA_SITE_KEY = "6LcJCcsSAAAAAD_LfK2OzEuUeBd1-bC-4dkxGISy";

export const ReCaptcha = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="g-recaptcha" data-sitekey={RECAPTCHA_SITE_KEY}></div>
  );
};