
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ArrowUpRight, Twitter, Instagram, Menu, X } from "lucide-react";

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        isScrolled ? "bg-[#233144] py-4" : "bg-[#233144] py-6"
      }`}
    >
      <div className="container mx-auto px-6">
        <div className="flex items-center justify-between">
          <Link to="/" className="flex items-center">
            <img 
              src="/lovable-uploads/e731a013-5832-45fa-be5f-9300697e20db.png" 
              alt="Cody McLain Logo" 
              className="h-8"
            />
          </Link>

          {/* Mobile menu button */}
          <button 
            className="md:hidden text-white"
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6" />
            ) : (
              <Menu className="h-6 w-6" />
            )}
          </button>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center space-x-8">
            <NavLink to="/about">About</NavLink>
            <NavLink to="/book">Book</NavLink>
            <NavLink to="/articles">Articles</NavLink>
            <NavLink to="https://www.mindhack.com/" external>
              Podcast <ArrowUpRight className="inline-block w-4 h-4 -mt-1" />
            </NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <div className="flex items-center space-x-4 ml-4">
              <a 
                href="https://twitter.com/codymclain" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition-colors duration-200 relative group"
              >
                <Twitter className="w-5 h-5" />
                <span className="absolute -bottom-1 left-1/2 w-0 h-0.5 bg-white transition-all duration-200 group-hover:w-full group-hover:left-0" />
              </a>
              <a 
                href="https://instagram.com/codymclain" 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition-colors duration-200 relative group"
              >
                <Instagram className="w-5 h-5" />
                <span className="absolute -bottom-1 left-1/2 w-0 h-0.5 bg-white transition-all duration-200 group-hover:w-full group-hover:left-0" />
              </a>
            </div>
          </div>

          {/* Mobile Navigation */}
          {isMobileMenuOpen && (
            <div className="absolute top-full left-0 right-0 bg-[#233144] md:hidden p-4 space-y-4">
              <div className="flex flex-col space-y-4">
                <NavLink to="/about">About</NavLink>
                <NavLink to="/book">Book</NavLink>
                <NavLink to="/articles">Articles</NavLink>
                <NavLink to="https://www.mindhack.com/" external>
                  Podcast <ArrowUpRight className="inline-block w-4 h-4 -mt-1" />
                </NavLink>
                <NavLink to="/contact">Contact</NavLink>
                <div className="flex space-x-4 pt-4">
                  <a 
                    href="https://twitter.com/codymclain" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-white hover:text-gray-300 transition-colors duration-200"
                  >
                    <Twitter className="w-5 h-5" />
                  </a>
                  <a 
                    href="https://instagram.com/codymclain" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-white hover:text-gray-300 transition-colors duration-200"
                  >
                    <Instagram className="w-5 h-5" />
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

const NavLink = ({
  to,
  children,
  external,
}: {
  to: string;
  children: React.ReactNode;
  external?: boolean;
}) => {
  const baseClasses =
    "text-white hover:text-gray-300 transition-colors duration-200";
  
  if (external) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={baseClasses}>
        {children}
      </a>
    );
  }
  
  return (
    <Link to={to} className={baseClasses}>
      {children}
    </Link>
  );
};

export default Navigation;
