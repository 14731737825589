
import { stripHtmlTags } from '../utils';

interface ParagraphBlockProps {
  text?: string;
}

const ParagraphBlock = ({ text = '' }: ParagraphBlockProps) => {
  // First clean any HTML and preserve line breaks
  const cleanedText = stripHtmlTags(text)
    // Convert Windows line endings to Unix style
    .replace(/\r\n/g, '\n')
    // Preserve line breaks but remove excess whitespace
    .split('\n')
    .map(line => line.trim())
    .join('\n')
    // Ensure proper spacing after periods
    .replace(/\.(?=\S)/g, '. ')
    .trim();

  return (
    <p className="whitespace-pre-wrap mb-6">
      {cleanedText}
    </p>
  );
};

export default ParagraphBlock;
