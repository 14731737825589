
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { Trash2, Pencil } from "lucide-react";

interface Category {
  id: number;
  name: string;
}

export const CategoryManagement = () => {
  const [newCategory, setNewCategory] = useState("");
  const [editingCategory, setEditingCategory] = useState<Category | null>(null);
  const { toast } = useToast();
  
  const { data: categories, refetch } = useQuery({
    queryKey: ["categories"],
    queryFn: async () => {
      console.log("Fetching categories");
      const { data, error } = await supabase
        .from("categories")
        .select("*")
        .order("name");
        
      if (error) throw error;
      return data as Category[];
    },
  });

  const handleAddCategory = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newCategory.trim()) return;

    try {
      const { error } = await supabase
        .from("categories")
        .insert([{ name: newCategory.trim() }]);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Category added successfully",
        className: "bg-green-500 text-white",
      });

      setNewCategory("");
      refetch();
    } catch (error) {
      console.error("Error adding category:", error);
      toast({
        title: "Error",
        description: "Failed to add category",
        variant: "destructive",
      });
    }
  };

  const handleEditCategory = async (id: number, newName: string) => {
    try {
      const { error } = await supabase
        .from("categories")
        .update({ name: newName })
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Category updated successfully",
        className: "bg-green-500 text-white",
      });

      setEditingCategory(null);
      refetch();
    } catch (error) {
      console.error("Error updating category:", error);
      toast({
        title: "Error",
        description: "Failed to update category",
        variant: "destructive",
      });
    }
  };

  const handleDeleteCategory = async (id: number) => {
    try {
      const { error } = await supabase
        .from("categories")
        .delete()
        .eq("id", id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "Category deleted successfully",
        className: "bg-green-500 text-white",
      });

      refetch();
    } catch (error) {
      console.error("Error deleting category:", error);
      toast({
        title: "Error",
        description: "Failed to delete category",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">Manage Categories</h2>
      
      <form onSubmit={handleAddCategory} className="flex gap-2">
        <Input
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="New category name"
          className="max-w-xs"
        />
        <Button 
          type="submit"
          className="bg-primary text-white hover:bg-primary-soft"
        >
          Add Category
        </Button>
      </form>

      <div className="space-y-2">
        {categories?.map((category) => (
          <div key={category.id} className="flex items-center justify-between bg-accent p-3 rounded">
            {editingCategory?.id === category.id ? (
              <form 
                onSubmit={(e) => {
                  e.preventDefault();
                  const input = e.currentTarget.querySelector('input');
                  if (input) {
                    handleEditCategory(category.id, input.value);
                  }
                }}
                className="flex-1 mr-2"
              >
                <Input
                  defaultValue={category.name}
                  autoFocus
                  onBlur={(e) => handleEditCategory(category.id, e.target.value)}
                />
              </form>
            ) : (
              <span className="text-primary">{category.name}</span>
            )}
            <div className="flex gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setEditingCategory(category)}
                className="text-primary hover:text-primary-soft"
              >
                <Pencil className="h-4 w-4" />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => handleDeleteCategory(category.id)}
                className="text-red-500 hover:text-red-700"
              >
                <Trash2 className="h-4 w-4" />
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
