import { Link } from "react-router-dom";
import { BlogPost } from "@/types";

interface ArticleGridProps {
  posts: BlogPost[];
}

const ArticleGrid = ({ posts }: ArticleGridProps) => {
  return (
    <section>
      <h2 className="text-3xl font-serif mb-12 text-white text-left">Latest Articles</h2>
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {posts?.map((post) => (
          <Link
            key={post.id}
            to={`/articles/${post.slug}`}
            className="group block bg-white rounded-2xl hover-lift"
          >
            <article className="p-6">
              {post.featured_image && (
                <div className="aspect-[16/9] mb-6 overflow-hidden rounded-lg">
                  <img
                    src={post.featured_image}
                    alt={post.title}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                </div>
              )}
              <h3 className="text-xl font-semibold text-left line-clamp-2">
                {post.title}
              </h3>
            </article>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default ArticleGrid;