
import { stripHtmlTags } from '../utils';

interface ImageBlockProps {
  url?: string;
  caption?: string;
}

const ImageBlock = ({ url = '', caption }: ImageBlockProps) => {
  return (
    <figure className="my-8">
      <img 
        src={url} 
        alt={caption || ''} 
        className="rounded-lg"
      />
      {caption && (
        <figcaption className="text-center text-sm text-gray-500 mt-2">
          {stripHtmlTags(caption)}
        </figcaption>
      )}
    </figure>
  );
};

export default ImageBlock;
