import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { UseFormReturn } from "react-hook-form";
import { ContactFormValues, FormFieldProps } from "./types";

interface ContactFormFieldProps extends FormFieldProps {
  form: UseFormReturn<ContactFormValues>;
}

export const ContactFormField = ({
  form,
  label,
  name,
  placeholder,
  type = "text",
  className,
}: ContactFormFieldProps) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="text-left">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            {name === "message" ? (
              <Textarea
                placeholder={placeholder}
                className={`min-h-[150px] ${className || ""}`}
                {...field}
              />
            ) : (
              <Input
                type={type}
                placeholder={placeholder}
                className={className}
                {...field}
              />
            )}
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};