
import { useEffect, useState } from 'react';
import HeaderBlock from './content-blocks/HeaderBlock';
import ParagraphBlock from './content-blocks/ParagraphBlock';
import ListBlock from './content-blocks/ListBlock';
import ImageBlock from './content-blocks/ImageBlock';
import CodeBlock from './content-blocks/CodeBlock';
import EmbedBlock from './content-blocks/EmbedBlock';
import QuoteBlock from './content-blocks/QuoteBlock';
import TableBlock from './content-blocks/TableBlock';
import WarningBlock from './content-blocks/WarningBlock';
import ChecklistBlock from './content-blocks/ChecklistBlock';

interface Block {
  type: string;
  data: {
    text?: string;
    level?: number;
    items?: string[] | Array<{
      text: string;
      checked: boolean;
    }>;
    style?: 'ordered' | 'unordered';
    url?: string;
    caption?: string;
    code?: string;
    content?: string[] | string[][];
    service?: string;
    embed?: string;
    source?: string;
    title?: string;
    message?: string;
    html?: string;
  };
}

interface EditorJsData {
  blocks: Block[];
}

interface ContentRendererProps {
  content: string;
  format?: 'html' | 'editorjs';
}

const ContentRenderer = ({ content, format = 'html' }: ContentRendererProps) => {
  const [parsedContent, setParsedContent] = useState<EditorJsData | null>(null);

  useEffect(() => {
    if (format === 'editorjs' && content) {
      try {
        setParsedContent(typeof content === 'string' ? JSON.parse(content) : content);
      } catch (e) {
        console.error('Error parsing Editor.js content:', e);
        setParsedContent(null);
      }
    }
  }, [content, format]);

  if (format === 'html' || !parsedContent) {
    return (
      <div 
        className="prose prose-lg max-w-none"
        dangerouslySetInnerHTML={{ __html: content }} 
      />
    );
  }

  return (
    <div className="prose prose-lg max-w-none">
      {parsedContent.blocks.map((block, index) => {
        switch (block.type) {
          case 'header':
            return <HeaderBlock key={index} level={block.data.level} text={block.data.text} />;

          case 'paragraph':
            return <ParagraphBlock key={index} text={block.data.text} />;

          case 'list':
            return <ListBlock key={index} items={block.data.items} style={block.data.style} />;

          case 'image':
            return <ImageBlock key={index} url={block.data.url} caption={block.data.caption} />;

          case 'code':
            return <CodeBlock key={index} code={block.data.code} />;

          case 'delimiter':
            return <hr key={index} className="my-8" />;

          case 'embed':
            return <EmbedBlock key={index} embed={block.data.embed} />;

          case 'quote':
            return <QuoteBlock key={index} text={block.data.text} caption={block.data.caption} />;

          case 'raw':
            return (
              <div 
                key={index}
                dangerouslySetInnerHTML={{ __html: block.data.html || '' }}
                className="my-4"
              />
            );

          case 'table':
            return <TableBlock key={index} content={block.data.content} />;

          case 'warning':
            return <WarningBlock key={index} title={block.data.title} message={block.data.message} />;

          case 'checklist':
            return <ChecklistBlock key={index} items={block.data.items as Array<{ text: string; checked: boolean }>} />;

          default:
            console.warn(`Unsupported block type: ${block.type}`);
            return null;
        }
      })}
    </div>
  );
};

export default ContentRenderer;
