import { useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "@/hooks/use-toast";
import { Button } from "@/components/ui/button";
import { supabase } from "@/integrations/supabase/client";
import { Form } from "@/components/ui/form";
import { ContactFormField } from "./contact/FormField";
import { ReCaptcha } from "./contact/ReCaptcha";
import { ContactFormValues } from "./contact/types";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  
  const form = useForm<ContactFormValues>({
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  const onSubmit = async (data: ContactFormValues) => {
    setIsSubmitting(true);
    try {
      const recaptchaToken = await new Promise((resolve, reject) => {
        if (!window.grecaptcha?.getResponse) {
          reject(new Error("reCAPTCHA not loaded"));
          return;
        }
        const token = window.grecaptcha.getResponse();
        if (token) {
          resolve(token);
        } else {
          reject(new Error("Please complete the reCAPTCHA"));
        }
      });

      const { error } = await supabase.functions.invoke('send-contact-email', {
        body: {
          ...data,
          recaptchaToken,
        },
      });

      if (error) throw error;
      
      toast({
        title: "Message sent!",
        description: "Thank you for reaching out. I'll get back to you soon.",
      });
      
      form.reset();
      window.grecaptcha.reset();
    } catch (error) {
      console.error('Contact form error:', error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "There was a problem sending your message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <ContactFormField
          form={form}
          name="name"
          label="Name"
          placeholder="Your name"
        />

        <ContactFormField
          form={form}
          name="email"
          label="Email"
          type="email"
          placeholder="your.email@example.com"
        />

        <ContactFormField
          form={form}
          name="message"
          label="Message"
          placeholder="Your message here..."
        />

        <ReCaptcha />

        <Button
          type="submit"
          className="w-full bg-primary text-white hover:bg-primary-soft"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Sending..." : "Send Message"}
        </Button>
      </form>
    </Form>
  );
};

export default ContactForm;